<!-- Localized -->
<template>
  <input
    v-model.trim="inputValue"
    class="form-control max-w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid focus:ring-blue-500 focus:border-blue-500 rounded-xl transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-brand focus:outline-none -mr-2 sm:-mr-0 w-full border-box h-10"
    :class="{ 'border-box-stroke': !error, 'border-red-600': error }"
    :placeholder="getPlaceHolder"
    :type="getInputType"
    :disabled="disabled || formAlreadySubmitted"
    @input="onInput"
  />
</template>

<script>
import debounce from 'lodash/debounce';

export default {
  props: {
    fieldData: {
      type: Object,
      require: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    formAlreadySubmitted: {
      type: Boolean,
    },
  },
  data() {
    return {
      inputValue: this.fieldData?.value?.trim() || '',
    };
  },
  computed: {
    getInputType() {
      return this.fieldData.inputType === 'phone' ? 'tel' : this.fieldData.inputType || 'text';
    },
    getPlaceHolder() {
      return this.fieldData.placeholder || '';
    },
  },
  created() {
    this.debouncedUpdateValue = debounce(this.updateValue, 300); // Debounce for 500 milliseconds to resolve the input value log
  },
  methods: {
    onInput(newValue) {
      this.inputValue = newValue?.target?.value;
      this.debouncedUpdateValue(newValue);
    },
    updateValue(newValue) {
      this.$emit('input', newValue?.target?.value); 
      this.$emit('handleFieldValueChange', newValue?.target?.value); 
    },
  },
};
</script>

<style lang="scss" scoped>
input:focus {
  box-shadow: 0 0 0 1px rgba(37, 99, 235, var(--tw-border-opacity)) !important;
}


input{
  height: 44px;
}


@media screen and (max-width:768px){
  input {
    border-radius:0px;
    margin:auto;
    width:100%;
  }
}
</style>
